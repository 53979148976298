<template>
    <div class="tool-scenes-container">
        <div class="tool-scenes-inner-container">
            <div class="tool-scene-item-container" :class="{'tool-scene-item-container-active':item.id==currentSceneId}" v-for="item in scenes" :key="item.id" @click="onSceneClick(item)">
                <img class="scene-thumb" :src="item.cover" />
            <div class="scene-name ellipsis">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("webappPanoModule", ["scenes", "currentSceneId"]),
    },
    methods: {
        ...mapActions("webappPanoModule", ["onSceneSelect"]),
        onSceneClick(scene) {
            this.onSceneSelect(scene.id);
        },
    },
};
</script>
<style lang="scss" scoped>
.tool-scenes-container {
    position: absolute;
    left: 15px;
    bottom: 5vh;
    width: calc(100vw - 30px);
    height: 94px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    // justify-content: center;
    .tool-scenes-inner-container {
        position: absolute;
        display: flex;
        padding: 10px;
        padding-right: 0px;
        border-radius: 12px;
        background: rgba($pano-framework-tool-background, 0.5);
        .tool-scene-item-container {
            border-radius: 6px;
            overflow: hidden;
            border: solid 2px transparent;
            position: relative;
            min-width: 70px;
            margin-right: 10px;
            .scene-thumb {
                width: 70px;
                height: 70px;
            }
            .scene-name {
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 4px 4px;
                text-align: center;
                color: $pano-font-color;
                background: rgba($pano-framework-tool-background, 0.6);
            }
        }
        .tool-scene-item-container-active {
            border: solid 2px $pano-theme;
        }
    }
}
</style>