<template>
    <div>
        <div class="siderBar">
            <div class="open" @click="open">
                <!-- v-if="openStatus" -->
                <img src="@/assets/shouqi.png"
                    :style="{'transform' : openStatus? '':'rotate(180deg)'}" alt="" />

                <!-- <img src="@/assets/shouqi.png" style="transform: ;" alt="" /> -->

                <div>{{ openStatus ? '收起' : "展开"}} </div>
            </div>
            <div class="jump" v-if="openStatus">
                <div class="jumpItem" @click="shop">
                    <img src="@/assets/shangcheng.png" alt="" />
                    <div>线上商铺</div>
                </div>
                <div class="jumpItem" @click="hotel">
                    <img src="@/assets/minsu.png" alt="" />
                    <div>酒店民宿</div>
                </div>
                <div class="jumpItem" @click="restaurant">
                    <img src="@/assets/canyin.png" alt="" />
                    <div>饭店预订</div>
                </div>
                <div class="jumpItem" @click="college">
                    <img src="@/assets/xueyuan.png" alt="" />
                    <div>振兴学院</div>
                </div>
            </div>
        </div>
        <div class="pano_bottom_item other skinItem3 on">
            <div class="hyroul-body tstion" id="hyroul-body tstion">
                <div class="menuWrap">
                    <div class="hyrlbg_sub hymenu_sub_bg height0"></div>
                    <div class="hyrlbg_ind hymenu_industry_bg" v-show="showFootArea"></div>
                    <div class="hyrlbg hymenu_bg" v-show="showFootArea"></div>
                    <div class="hyrlbg2"
                        :style="{'transform' : showFootArea? '':'translate(-50%, -50%)  scale(0.6,0.8)'}">
                    </div>

                    <div class="hymenu_sub height0" id="hymenu_sub"></div>
                    <div class="hymenu_industry" id="hymenu_industry" v-show="showFootArea"
                        style="transform: rotate(15deg);">
                        <span class="on"
                            style="transform-origin: 50% 100%; transform: rotate(-15deg);"><a
                                datarotate="-15" data-typee="two" data-groupid="2" data-gid="1"
                                class="acr4"><span class="char1"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-4deg);">乌</span><span
                                    class="char2"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-1deg);">岩</span><span
                                    class="char3"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(1deg);">头</span><span
                                    class="char4"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(4deg);">村</span></a></span>
                    </div>

                    <div class="hymenu" id="hymenu_area" v-show="showFootArea"
                        :style="skyStatus == 1 ? 'transform: rotate(12deg);' : 'transform: rotate(-8deg);'">
                        <span @click="sky(1)" :class="skyStatus == 1 ? 'on' : ''"
                            style="transform-origin: 50% 100%; transform: rotate(-12deg);"><a
                                datarotate="-12" data-typee="scene" data-title="乌岩头村"
                                data-scenename="scene1626415" data-group-index="1"
                                data-this-index="0" class="acr4"><span class="char1"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-4deg);">乌</span><span
                                    class="char2"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-1deg);">岩</span><span
                                    class="char3"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(1deg);">头</span><span
                                    class="char4"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(4deg);">村</span></a></span>
                        <!-- <span @click="sky(2)"
                            style="transform-origin: 50% 100%; transform: rotate(8deg);"
                            :class="skyStatus == 2 ? 'on' : ''">
                            <a datarotate="8" data-typee="scene" data-title="枕山酒店上空"
                                data-scenename="scene1626416" data-group-index="1"
                                data-this-index="1" class="acr6"><span class="char1"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(2px) rotate(-8deg);">枕</span><span
                                    class="char2"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-5deg);">山</span><span
                                    class="char3"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-1deg);">酒</span><span
                                    class="char4"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(1deg);">店</span><span
                                    class="char5"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(5deg);">上</span><span
                                    class="char6"
                                    style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(2px) rotate(8deg);">空</span></a></span>
                     -->
                    </div>
                    <!-- <div v-else class="hymenu" id="hymenu_area" style="transform: rotate(-8deg);">
            <span
              @click="sky"
              class=""
              style="transform-origin: 50% 100%; transform: rotate(-12deg);"
              ><a
                datarotate="-12"
                data-typee="scene"
                data-title="乌岩头村"
                data-scenename="scene1626415"
                data-group-index="1"
                data-this-index="0"
                class="acr4"
                ><span
                  class="char1"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-4deg);"
                  >乌</span
                ><span
                  class="char2"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-1deg);"
                  >岩</span
                ><span
                  class="char3"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(1deg);"
                  >头</span
                ><span
                  class="char4"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(4deg);"
                  >村</span
                ></a
              ></span
            >
            <span style="transform-origin: 50% 100%; transform: rotate(8deg);" class="on"
              ><a
                datarotate="8"
                data-typee="scene"
                data-title="枕山酒店上空"
                data-scenename="scene1626416"
                data-group-index="1"
                data-this-index="1"
                class="acr6"
                ><span
                  class="char1"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(2px) rotate(-8deg);"
                  >枕</span
                ><span
                  class="char2"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-5deg);"
                  >山</span
                ><span
                  class="char3"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(-1deg);"
                  >酒</span
                ><span
                  class="char4"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(1deg);"
                  >店</span
                ><span
                  class="char5"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(0px) rotate(5deg);"
                  >上</span
                ><span
                  class="char6"
                  style="display: inline-block; transition: none 0s ease 0s; transform: translateX(0px) translateY(2px) rotate(8deg);"
                  >空</span
                ></a
              ></span
            >
          </div> -->
                    <!--  -->
                    <div v-if="showFootArea" class="cbottom hylogo show" @click.stop="toggle"
                        style="transform-origin:50% 50%"
                        :style="{'transform' : showFootArea? '':'rotateX(180deg) translateY(-10px)'}">
                    </div>
                    <div v-else class="cbottom hylogo show" @click.stop="toggle"
                        style="transform:rotateX(180deg) translateY(-10px) ;"></div>
                    <!--  -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      openStatus: true,
      showFootArea: true,
      skyStatus: 1,
    }
  },
  methods: {
    toggle() {
      this.showFootArea = !this.showFootArea;
    },
    shop() {
      wx.miniProgram.switchTab({
        url: '/pages/home/home',
      })
    },
    hotel() {
      // wx.miniProgram.showModal({
      //   content: "正在开发中..."
      // })
      this.$alert({content:'暂时未开通该功能'})
      // wx.miniProgram.switchTab({
      //   url: '/pages/home/home',
      // })
    },
    restaurant() {
      // wx.miniProgram.switchTab({
      //   url: '/pages/home/home',
      // })
      // wx.miniProgram.showModal({
      //   content: "正在开发中..."
      // })
      this.$alert({content:'暂时未开通该功能'})
    },
    college() {
      // wx.miniProgram.navigateTo({
      //   url: '/pages/college/college',
      // })
      // wx.miniProgram.showModal({
      //   content: "正在开发中..."
      // })
      this.$alert({content:'暂时未开通该功能'})
    },
    open() {
      console.log(this.openStatus)
      this.openStatus = !this.openStatus
    },
    sky(num) {
      console.log(num)
      this.skyStatus = num
    },
  },
}
</script>

<style lang="scss" scoped>
.siderBar {
  position: absolute;
  left: 16px;
  // top: 234px;
  top: 30vh;
  .open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 76px;
    opacity: 1;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.3);
    margin-bottom: 12px;
    img {
      width: 24px;
      height: 24px;
    }
    div {
      margin-top: 5px;
      font-size: 12px;
      color: #fff;
    }
  }
  .jump {
    width: 60px;
    height: 256px;
    opacity: 1;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.3);
    .jumpItem {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 60px;
      height: 62px;
      img {
        width: 24px;
        height: 24px;
      }
      div {
        margin-top: 5px;
        font-size: 12px;
        color: #fff;
      }
    }
    .jumpItem:first-child {
      border-radius: 10px 10px 0 0;
    }
    .jumpItem:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}
.pano_bottom_item.on.skinItem3 {
  bottom: -60px;
}
.pano_bottom_item.skinItem3 {
  max-height: 250px;
  bottom: -300px;
  transition: bottom 0.1s linear 50ms;
}
.pano_bottom_item.on {
  bottom: 0;
  width: 100%;
}
.pano_bottom_item {
  position: fixed;
  left: 0;
  width: 100%;
  transition: bottom 0.1s linear 50ms;
  z-index: 4002;
  bottom: -100px;
  pointer-events: none;
}
.tstion {
  transition: all 0.3s ease-in-out;
}
.hyroul-body .height0 {
  height: 0.1vh !important;
}
.hyrlbg_sub {
  width: 84vh;
  height: 84vh;
}
.hyrlbg,
.hyrlbg2,
.hyrlbg_ind,
.hyrlbg_sub {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: saturate(150%) contrast(80%) blur(3px);
  -webkit-backdrop-filter: saturate(150%) contrast(80%) blur(8px);
  pointer-events: visible;
}
.hyrlbg_ind {
  width: 400px;
  height: 400px;
}
.hyrlbg {
  width: 320px;
  height: 320px;
}
.hyrlbg2 {
  width: 240px;
  height: 240px;
}
.hyrlbg2 {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}
.hyroul-body .height0 {
  height: 0.1vh !important;
}
// .hymenu_sub {
//   height: 2vh;
// }
.hymenu,
.hymenu_industry,
.hymenu_sub {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 14vh;
  border-radius: 50% 50% 0 0;
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.hymenu_industry {
  height: 23vh;
}
.hymenu > span,
.hymenu_industry > span,
.hymenu_sub > span {
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  color: #333;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  pointer-events: none;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  transition: all 0.5s ease-in-out;
}
.hymenu > span.on a,
.hymenu_industry > span.on a,
.hymenu_sub > span.on a {
  color: #fff;
  font-weight: 700;
  transform: translateY(10px);
}
.hymenu > span a,
.hymenu_industry > span a,
.hymenu_sub > span a {
  color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  font-size: 1.6vh;
  padding: 1.4vh;
  position: relative;
  font-weight: 400;
  display: inline-block;
  pointer-events: visible;
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  border-radius: 40px;
  -webkit-transition: all 1s ease-in-out;
}
a,
button,
input,
textarea {
  -webkit-tap-highlight-color: transparent;
}
.noselect,
a,
img {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
a,
img {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  text-decoration: none;
  color: #fff;
}
a,
img {
  border: 0;
}
a,
button,
img,
input,
textarea {
  border: none;
}
span.on a .char1,
span.on a .char2,
span.on a .char3,
span.on a .char4,
span.on a .char5,
span.on a .char6 {
  color: #fff;
}
.char1,
.char2,
.char3,
.char4,
.char5,
.char6 {
  color: rgba(255, 255, 255, 0.8);
}
a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
  list-style: none;
}
.hymenu > span.on a::after,
.hymenu_industry > span.on a::after,
.hymenu_sub > span.on a::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 2px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 110px 0 110px 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3px;
}
.hymenu > span,
.hymenu_industry > span,
.hymenu_sub > span {
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  color: #333;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  pointer-events: none;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  transition: all 0.5s ease-in-out;
}
i {
  font-style: inherit;
}
.hymenu {
  height: 29vh;
}
.hymenu {
  -webkit-transform-origin: 50% 100%;
}
.hymenu,
.hymenu_industry,
.hymenu_sub {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 14vh;
  border-radius: 50% 50% 0 0;
  -webkit-transform-style: preserve-3d;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.hyroul-body .cbottom.hylogo {
  width: 24px;
  height: 24px;
  // transform: translateY(1.8vh);
  // }
  // .hyroul-body .cbottom.hylogo {
  //   z-index: 98;
  // }
  // .hyroul-body .cbottom.hylogo {
  background: url(~@/assets/footer.png) no-repeat scroll center center;
  background-size: 100%;
  width: 24px;
  height: 24px;
  // transform: translateY(18px);
}
.hyroul-body .cbottom.show {
  bottom: 11vh;
  opacity: 1;
  visibility: visible;
}
.hyroul-body .cbottom {
  position: absolute;
  bottom: 1vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  // transition: all 0.8s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  text-align: center;
  background-size: 45px;
  transform: translateX(0);
  pointer-events: visible;
  cursor: pointer;
}
.show {
  display: inherit !important;
}
</style>
